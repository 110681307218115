/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900');

/* Root Variable*/
:root {
  --font-family-default: 'Inter', -apple-system, BlinkMacSystemFont, 'Apple SD Gothic Neo',
    'sans-serif';
  --font-weight-default: 400;
  --lineHeight-default: 1.8;
  --lineHeight-1: 1;
  --lineHeight-2: 1.2;
}

/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  /* device reset */
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  appearance: none;

  line-height: var(--lineHeight-default);
  font-weight: var(--font-weight-default);
}

body,
html {
  min-height: 100%;
}

body {
  font-family: var(--font-family-default);
  width: 100%;
  overflow-x: hidden;
  word-break: keep-all;
  overflow-wrap: break-word;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
a:link,
a:hover,
a:active,
a:visited {
  color: inherit;
  text-decoration: none;
}

input,
select,
textarea,
button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  vertical-align: middle;
  border-radius: 0;
}

caption {
  visibility: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

figure {
  line-height: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

*::selection {
  background-color: #183646;
  color: white;
}
